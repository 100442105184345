<template>
    <div class="w-100 pl-4 pb-2 mb-4">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t(`${name.toLowerCase()}.heading`)"></p>
                <p v-html="$tc(`${name.toLowerCase()}.subheading`, jobs.length)"></p>
            </v-card-text>
            <v-row>
                <v-col class="pl-6 pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="jobs"
                        :search="search"
                        :custom-filter="customFilter"
                        :loading="$store.getters.getLoaders.jobs"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                    >
                        <template v-slot:item.objtrans_ssid="{ item }">
                            <v-avatar
                                tile
                                v-if="item.objtrans_ssid"
                                size="35"
                            >
                                <v-img
                                    contain
                                    :src="require(`../assets/images/portale/${item.objtrans_ssid}.png`)"
                                    :lazy-src="require(`../assets/images/portale/${item.objtrans_ssid}.png`)"
                                    alt="Channel-Logo"
                                />
                            </v-avatar>
                            <v-avatar v-else size="35">
                                <v-icon :color="$store.getters.getColors.secondary1">
                                    mdi-image-area
                                </v-icon>
                            </v-avatar>
                        </template>
                        <template v-slot:item.objtrans_tstamp="{ item }">
                            {{ getElapsedTime(parseInt(item.objtrans_tstamp)) }}
                        </template>
                        <template v-slot:item.objtrans_isqueued="{ item }">
                            <v-icon x-small :color="(item.objtrans_isqueued ? $store.getters.getColors.success : $store.getters.getColors.error)">
                                mdi-checkbox-blank-circle
                            </v-icon>
                        </template>
                        <template v-slot:item.objtrans_memberid="{ item }">
                            {{ item.objtrans_memberid }}
                            <v-icon
                                class="rotating"
                                style="margin-bottom: 2px"
                                v-if="item.feondi_uebertragungabgeschlossen && item.feondi_uebertragungabgeschlossen === 0"
                                x-small
                                :color="$store.getters.getColors.error"
                            >
                                mdi-sync
                            </v-icon>
                        </template>
                        <template v-slot:item.objtrans_transstatus="{ item }">
                            <v-progress-circular
                                v-if="item.objtrans_transstatus"
                                :size="15"
                                indeterminate
                                :color="$store.getters.getColors.success"
                            />
                            <v-icon
                                v-else
                                x-small
                                :color="$store.getters.getColors.warning"
                            >
                                mdi-checkbox-blank-circle
                            </v-icon>
                        </template>
                        <template v-slot:item.objtrans_checkoutstatus="{ item }">
                            <v-icon
                                x-small
                                :color="(item.objtrans_checkoutstatus ? $store.getters.getColors.success : $store.getters.getColors.error)"
                            >
                                mdi-checkbox-blank-circle
                            </v-icon>
                        </template>
                        <template v-slot:item.objtrans_reasonnotsent="{ item }">
                            {{ item.objtrans_reasonnotsent }}
                        </template>
                        <template v-slot:item.objtrans_kebabmenu="{ item }">
                            <v-icon
                                small
                                :color="$store.getters.getColors.primary"
                                @click.stop="$refs.conMenu.showContextMenu($event, item)"
                            >
                                mdi-dots-vertical
                            </v-icon>
                        </template>
                    </v-data-table>
                    <context-menu
                        ref="conMenu"
                        :context-items="contextItems"
                        :context-item-ids="jobIds"
                    />
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ContextMenu from '@/components/Allgemein/Kontextmenue.vue'
import Search from '@/components/Allgemein/Suche.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import QueueService from '@/services/queue.service'

const name = 'Jobs'

export default {
    name: name,
    components: { Search, ContextMenu },
    metaInfo: {
        title: name,
    },
    sockets: {
        getJobs(data) {
            if (data.hasOwnProperty('jobs')) {
                this.jobs = data.jobs
                this.jobIds = this.jobs.map(elem => elem.objtrans_id)
            }
        },
    },
    data() {
        return {
            name: name,
            expanded: [],
            singleExpand: false,
            search: '',
            jobs: [],
            jobIds: [],
            clickedRowItem: {},
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getLocale',
            'getMembers',
            'getUser',
        ]),
        contextItems: function () {
            return [
                {
                    condition: () => {
                        return !!this.getUser['is_admin']
                    },
                    title: () => {
                        return {
                            text: this.$i18n.t('common.words.delete'),
                            color: this.getColors.error,
                        }
                    },
                    icon: () => {
                        return {
                            name: `mdi-delete`,
                            color: this.getColors.error,
                        }
                    },
                    action: async (item) => {
                        await QueueService.deleteJob(item.objtrans_id)
                        this.jobs = await QueueService.getAllJobs()
                    },
                },
                {
                    condition: () => {
                        return !!this.getUser['is_admin']
                    },
                    title: () => {
                        return {
                            text: this.$i18n.t('common.words.reset'),
                            color: this.getColors.secondary1,
                        }
                    },
                    icon: () => {
                        return {
                            name: `mdi-repeat`,
                            color: this.getColors.secondary1,
                        }
                    },
                    action: async (item) => {
                        await QueueService.resetJob(item.objtrans_id)
                        this.jobs = await QueueService.getAllJobs()
                    },
                },
            ]
        },
        headers: function () {
            return [
                {
                    text: '',
                    value: 'objtrans_ssid',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '25px',
                },
                {
                    text: this.$i18n.t('common.labels.createdAt'),
                    value: 'objtrans_tstamp',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.ids.memberid'),
                    value: 'objtrans_memberid',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.ids.propertyid'),
                    value: 'objtrans_objid',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.words.job'),
                    value: 'objtrans_funktion',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.queued'),
                    value: 'objtrans_isqueued',
                    align: 'center',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.labels.status'),
                    value: 'objtrans_transstatus',
                    align: 'center',
                    divider: true,
                    width: '110px',
                },
                {
                    text: this.$i18n.t('common.labels.checkout'),
                    value: 'objtrans_checkoutstatus',
                    align: 'center',
                    divider: true,
                    width: '210px',
                },
                {
                    text: this.$i18n.t('common.labels.reasonnotsend'),
                    value: 'objtrans_reasonnotsent',
                    align: 'left',
                    divider: true,
                    width: '310px',
                },
                {
                    text: '',
                    value: 'objtrans_kebabmenu',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '25px',
                },
            ]
        },
    },
    methods: {
        customFilter(value, search, item) {
            let test = false

            //Info: check only numeric search values
            if (/\d/.test(search))
                test = (item.hasOwnProperty('objtrans_memberid') && item.objtrans_memberid.toString().includes(search.toString()))

            //Info: check only alpha numeric search values
            if (/([a-zA-z])/.test(search))
                test = (item.hasOwnProperty('objtrans_funktion') && item.objtrans_funktion.toString().toLowerCase().includes(search.toString().toLowerCase())) || (item.hasOwnProperty('ss_textid') && item.ss_textid.toString().toLowerCase().includes(search.toString().toLowerCase()))

            return test
        },
        getImage(ssid) {

            if (ssid)
                return `../assets/images/portale/${ ssid }.png`

            return ''
        },
        getElapsedTime(timestamp) {
            moment.locale(this.getLocale)
            return moment.unix(timestamp).fromNow()
        },
    },
    async created() {
        await this.$store.dispatch('setLoaders', { jobs: true })
        this.jobs = await QueueService.getAllJobs()
        await this.$store.dispatch('setLoaders', { jobs: false })
    },
}
</script>
