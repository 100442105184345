<template>
    <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
    >
        <v-list min-width="220px">
            <v-layout v-for="(contextItem, contextIndex) in contextItems" :key="`contextIdex-${contextIndex}`">
                <v-list-item
                    ripple
                    :disabled="!contextItem.condition()"
                    :color="getColors.secondary1"
                    @click="contextItem.action(item)"
                >
                    <v-list-item-action class="mr-3">
                        <v-icon small :color="contextItem.icon().color">
                            {{ contextItem.icon().name }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :style="`color: ${contextItem.title().color}`">
                            {{ contextItem.title().text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-layout>
        </v-list>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ContextMenu',
    props: {
        contextItems: Array,
        contextItemIds: Array,
    },
    data() {
        return {
            item: null,
            showMenu: false,
            x: 0,
            y: 0,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
        ]),
    },
    watch: {
        contextItemIds(newVal) {
            if (this.item && !newVal.includes(this.item.objtrans_id))
                this.showMenu = false
        },
    },
    methods: {
        showContextMenu(e, item) {

            logger.log(`this is e in click show context menu: `, e)
            logger.log(`this is rowItem in click show context menu: `, item)

            this.item = item

            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY

            this.$nextTick(() => {
                this.showMenu = true
            })
        },
    },
}
</script>
